.rce-mbox-file {
    padding-bottom: 13px;
}

.rce-mbox-file > button {
    background: #c8ced3;
    display: flex;
    border-radius: 5px;
    margin-top: -3px;
    margin-right: -6px;
    margin-left: -6px;
    align-items: center;
    min-height: 52px;
    max-width: 25vw;
    min-width: 10vw;
    padding: 5px 0;
    cursor: pointer;
    user-select: none;
    outline: none;
    border:none;
}

.rce-mbox-file > button > * {
    padding: 0px 10px;
}

.rce-mbox-file--icon {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.rce-mbox-file--size {
    font-size: 10px;
    margin-top: 3px;
    max-width: 52px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rce-mbox-file--text {
    font-size: 13.6px;
    word-break: break-word !important;
    white-space: normal !important;
}

.rce-mbox-file--buttons {
    font-size: 30px;
    align-items: center;
    display: flex;
}
.rce-mbox-file--buttons .rce-error-button {
    display: inherit;
}

.rce-mbox-file--loading {
    font-size: 15px;
    width: 40px;
    height: 40px;
}
