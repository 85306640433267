.payeralert {
	.alert {
		
		display: table ;
		width: 100% ;
		min-height: 64px ;
		
		.payeralert-text {
			display: table-cell ;
			vertical-align: middle;
			padding-left: 64px
			
		}
	}
}
