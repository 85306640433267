// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
// @import '~@coreui/coreui/scss/_dropdown-menu-right.scss';
@import '~@coreui/coreui/scss/_dropdown.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";



.devbanner {
    padding-top: 20px;
    background-color: #aaaaaa;
    background-image: url(../assets/img/dev_layer.png);
}
