html, body, #root {
    height: 100%
} 

.app {
    height: calc(100% - 20px);
}

body {
    background-color: rgba(0, 0, 0, 0.125);
}

.app-body {
    height: calc(100% - 84px);
}

.navbar {
    background-color: white !important;
    border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
    box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.2);
}

button.btn-primary {
    background-color: #20a8d8 !important;
    border-color: #20a8d8 !important;
}

div.ant-float-btn-group > button.ant-float-btn-primary > div.ant-float-btn-body {
    background-color: #20a8d8 !important;
}