.no-border {
    box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
    border-bottom-width: 1px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
}

hr {
  margin-top: 0.5rem ;
  margin-bottom: 0.5rem;
}


.accueil-td {
  border-right-color: rgba(0, 0, 0, 0.1);
  border-right-style: solid;
  border-right-width: 2px;
  border-collapse: collapse;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 1.1rem;
}

.accueil-td-text{
  text-align: center;
  font-size: 1.2rem;

}

.accueil-td-first {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-collapse: collapse;
  text-align: center;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
}

.accueil-td-last {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 1.5rem;
}

.obs-header-column{
  vertical-align: top !important;
}

#nouveau-message-accueil{
  margin-top: 15px;
}
@media (max-width: 991px){
  .col-accueil{
    flex: none !important;
    max-width: none;
  }
  #button-payer-partiel-accueil{
    margin-top: 0;
  }
}

.table-striped tbody tr:nth-of-type(odd){
  background-color: #e6f9ff ;
}
