.mbox-container{
    flex-direction: column;
    display: block;
    overflow: hidden;
    min-width: 200px;
}

.mbox-left, .mbox-right{
    flex-direction: column;
    display: block;
    overflow: hidden;
    margin:10px;
    padding:10px;
    position: relative;
    min-width: 15vw;
    max-width: 25vw;
}
.rce-mbox-file > button{
    max-width: none !important;
}

.mbox-left {
    background:#c8ced3;
    float: left;
    border-radius: 25px 25px 25px 0;
}

.mbox-right {
    background:#20a8d8;
    float: right;
    border-radius: 25px 25px 0 25px;
}

.mbox-body{
    margin: 5px;
    padding: 0;
    position: relative;
}

.mbox-text{
    font-size: 14px;
    padding-bottom: 20px;
    color: #2f353a;
    white-space: pre-line;
}

.mbox-title-left, .mbox-title-right{
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 700;
    margin: 0 10px;
}

.mbox-title-left:before, .mbox-title-right:before{
    content: attr(data-text);
}

.chatRow-left{
    margin-right: 50% !important;
}

.chatRow-right{
    margin-left: 50% !important;
    justify-content: flex-end;
}