// Here you can add other styles

// CSS for chat sidebar
html:not([dir="rtl"]) .sidebar{
  margin-left: -400px;
}
.navbar{
  display: block !important;
}

.navbar-center{
  text-align: center;
  display: inline-block;
  position: absolute;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
  margin-top: 27.5px;
}

.text-user{
  text-align: center;
  display: inline-block;
  float: right;
  margin-top: 27.5px;
  transform: translateY(-50%);
}

.app-header .nav-item{
  display: inline-block;
}
.user-logout{
  list-style: arabic-indic !important;
  display: inline-block !important;
  float: right;
  margin-top: 27.5px !important;
  transform: translateY(-50%);
}
@media (min-width: 992px){
  .sidebar-fixed .sidebar{
    width: 400px
  }
  .header-fixed .app-header {
    position: unset !important;
  }

}

@media (max-width: 690px) {
  .app-header .navbar-brand{
    display: none;
  }

}
@media (max-width: 991.98px){
  .sidebar{
    width: 300px
  }
  .app-header .navbar-brand{
    position: unset;
    margin-left: 0;
  }
  .header-fixed .app-header {
    position: unset !important;
  }
  .app-header{
    text-align: unset;
  }

}

.app-body .sidebar{
  flex: 0 0 400px
}

.sidebar{
  color: transparent;
}

@media (min-width: 992px){
  html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 400px;
  }
}


// End css for chat sidebar

// Hide pj button on small screen
@media (max-width: 992px){
  #pj-button{
    display: none;
  }
}
#submitUserInfos{
  margin-right: 5px;
}

.rce-mbox-file--text{
  color: #2f353a
}

.rce-mbox-file--size{
  color: #2f353a
}
.rce-container-mlist{
  overflow: auto;
}

.userInfosTitleHeader{
  display: inline-block;
  margin-top: 5px;
}

.userInfosTitleHeaderBtoB{
  display: inline-block;
  margin-top: 5px;
  margin-left: 80px;
}
.userInfosAddInfos{
  float: right;
}

#colPagination{
  padding-top: 8px;
}

.userInfosCards{
  padding-top: 20px;
}

#userInfosCollapsibleCard{
  border: none
}

.userInfosCollapsibleCardHeader{
  border: 1px solid #e5e5e5;
  margin-bottom: 2px;
}

.pagination{
  float: right;
}

.table-bordered{
  border: none;
}

.table-responsive {
  max-width: 100%;
  overflow-x: auto;
}

.table thead th{
  min-width: 0px
}

/*
.table thead th:nth-child(n+3) {
  min-width: 200px;
}
*/
.divAdrbookBtoB{
  text-align: center;
  padding-bottom: 20px;
}

.btobAdrbook{
  margin-top: 10px;
  margin-bottom: 10px;
}

.divAddAddressStrong{
  display: inline-block;
  padding-top: 7px;
}

.divAddAddressButton{
  display: inline-block;
  float: right;
}

.postAddr{
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
}

.postAddrRue{
  margin-right: 3px;
}

.postAddrCol{
  margin-top: 10px;
}

.cardWidgetBtoB{
  display: inline-block;
  padding-top: 5px !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.iconBtoBAdrbook{
  float: left;
}

.adrBtoBAdrbook{
  padding-top: 20px;
  padding-bottom: 10px;
  margin-right: 10px;
}

.entityNameCenter{
  text-align: center;
}

.toggleCustom{
  padding: 0 !important;
  background-color: transparent;
  border-color: transparent;
}

.row-width-full{
  width: 100%;
  padding-left: 1.25rem;
}

.records-sorting-asc{
  display: flex ;
  flexDirection: column ;
  minWidth: 200px ;
}

.records-sorting-desc{
   display: flex ;
   flexDirection: column ;
   minWidth: 200px ;
 }


.control-label:after {
  content:"*";
  color:red;
}


.navlink-active{
  border-bottom: solid 2px blue !important;
  font-weight: 500 !important;
  color: #2f353a !important;

}
